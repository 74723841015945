import React, { Component } from 'react'
import type {Node} from 'react'
import gql from 'graphql-tag'

import './MainLayout.css'
import { Query } from 'react-apollo'
import AuthService from '../../../services/AuthService'
import { NavLink } from 'react-router-dom'

const GET_LOGGED_USER = gql`
    {
        userMe {
            id
            firstName
            lastName
            email
            phone
            roles {
                id
                name
            }
        }
    }
`

type Props = {
  children: Node | Array<Node>
}
export default class MainLayout extends Component<Props> {

  onLogoutClick = () => {
    AuthService.instance.logout()
  }

  render () {
    const {children} = this.props

    return (

      <Query query={GET_LOGGED_USER}>
        {({loading, error, data}) => {
          let isAdmin = false
          let isUser = false
          if (!loading && data.userMe) {
            isAdmin = data.userMe.roles.filter(item => item.name === 'admin').length > 0
            isUser = data.userMe.roles.filter(item => item.name === 'user').length > 0
          }
          return (
            <React.Fragment>
              <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
                <a className="navbar-brand col-sm-3 col-md-2 mr-0" href="javascript:void(0);">
                  Hyperting Timesheets
                </a>
                <ul className="navbar-nav px-3">
                  <li className="nav-item text-nowrap">
                    <a
                      onClick={this.onLogoutClick}
                      className="nav-link"
                      href="javascript:void(0);">
                      Logout
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="container-fluid">
                <div className="row">
                  <nav className="col-md-2 d-none d-md-block bg-light sidebar" style={{position: 'fixed', zIndex: '1029'}}>
                    <div className="sidebar-sticky">
                      <ul className="nav flex-column">
                        {isAdmin && (
                          <React.Fragment>
                            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                              <span>ADMINISTRATION</span>
                            </h6>
                            <li className="nav-item">
                              <NavLink className="nav-link" activeClassName="active" to={`/timesheets/users`}>
                                Users
                              </NavLink>
                              <NavLink className="nav-link" activeClassName="active" to={`/timesheets/projects`}>
                                Projects
                              </NavLink>
                              <NavLink className="nav-link" activeClassName="active" to={`/timesheets/activities`}>
                                Activities
                              </NavLink>
                            </li>
                          </React.Fragment>
                        )}

                        {(isUser || isAdmin) && (
                          <React.Fragment>
                            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                              <span>WORK</span>
                            </h6>
                            <NavLink className="nav-link" activeClassName="active" to={`/timesheets/workActivities`}>
                              My Activities
                            </NavLink>
                          </React.Fragment>
                        )}
                      </ul>
                    </div>
                  </nav>
                  <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
                    {children}
                  </main>
                </div>
              </div>
            </React.Fragment>
          )}}
      </Query>
    )
  }
}
