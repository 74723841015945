import moment from 'moment'

export class ActivityUtils {
  static calculateTotalPauses (pauses: Array) {
    let duration = 0

    for (let index in pauses) {
      const pause = pauses[index]
      const start = moment(pause.start)
      if (!pause.end) {
        continue
      }

      const end = moment(pause.end)
      const ms = end.diff(start)
      duration += ms
    }
    return duration
  }
}
