import React, { Component } from 'react'
import MainLayout from '../../core/Layouts/MainLayout/MainLayout'
import { Redirect, Route, Switch } from 'react-router-dom'
import AdminUsers from './scenes/AdminUsers'
import AdminProjects from './scenes/AdminProjects'
import AdminActivities from './scenes/AdminActivities'
import UserActivities from './scenes/UserActivities'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'

type Props = {
  match: any,
  location: any
}

const GET_LOGGED_USER = gql`
    {
        userMe {
            id
            firstName
            lastName
            email
            phone
            roles {
                id
                name
            }
        }
    }
`

export default class TimeSheetsManager extends Component<Props> {
  render () {
    const {match} = this.props
    return (
      <MainLayout>
        <Query query={GET_LOGGED_USER}>
          {({loading, error, data}) => {
            if (loading) {
              return (<div>Caricamento in corso...</div>)
            }

            let isAdmin = false
            let isUser = false
            if (data.userMe) {
              isAdmin = data.userMe.roles.filter(item => item.name === 'admin').length > 0
              isUser = data.userMe.roles.filter(item => item.name === 'user').length > 0
            }
            let redirectPath = `${match.url}/workActivities`

            if (isUser) {
              redirectPath = `${match.url}/workActivities`
            }

            if (isAdmin) {
              redirectPath = `${match.url}/activities`
            }
            return (
              <Switch>
                {isAdmin && ([
                  <Route key={`route-users`} path={`${match.url}/users`} component={AdminUsers} />,
                  <Route key={`route-projects`} path={`${match.url}/projects`} component={AdminProjects} />,
                  <Route key={`route-activities`} path={`${match.url}/activities`} component={AdminActivities} />
                ])}
                {(isUser || isAdmin) && ([
                  <Route key={`route-workActivities`} path={`${match.url}/workActivities`} component={UserActivities} />
                ])}
                <Redirect to={redirectPath} />
              </Switch>
            )
          }}
        </Query>
      </MainLayout>
    )
  }
}
