import React, { Component } from 'react'
import gql from 'graphql-tag'
import { compose, graphql } from 'react-apollo'
import ActivityForm from '../components/ActivityForm'
import history from '../../../../../../history'

const GET_ACTIVITY_QUERY = gql`query GetActivitys($id: ID!) {
    activity(id: $id) {
        id
        description
        start
        end
        project {
            id
            name
        }
        user {
            id
            firstName
            lastName
            email
            phone
        }
    }
}`

const EDIT_ACTIVITY_MUTATION = gql`mutation EditActivity($id: ID!, $data: ActivityInput) {
    updateActivity(id: $id, data: $data) {
        id
    }
}`

const DELETE_ACTIVITY_MUTATION = gql`mutation DeleteActivity($id: ID!) {
    deleteActivity(id: $id)
}`

type Props = {
  editMutate: Function,
  deleteMutate: Function,
  data: Object
}

class Edit extends Component<Props> {
  constructor (props) {
    super(props)
  }

  onDeleteConfirm = async () => {
    try {
      const {deleteMutate, data} = this.props
      const {activity} = data
      const result = await deleteMutate({variables: {id: activity.id}})
      history.goBack()
    } catch (e) {
      console.log(e)
    }
  }

  onSubmit = async (values, { setSubmitting }) => {
    try {
      const { data } = this.props
      const { activity } = data

      const result = await this.props.editMutate({
        variables: {
          id: activity.id,
          data: {
            user: values.user.id,
            project: values.project.id,
            start: values.start,
            end: values.end,
            description: values.description
          }
        }
      })
      history.goBack()
    } catch (e) {
      console.error('errore', e)
    }
  }

  render () {
    const { data } = this.props
    const { loading } = data
    return (
      <div className="col-12">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Modifica Attività</h1>
        </div>
        <div className="col-8 offset-2">
          {loading ? (
            <span>Caricamento in corso...</span>
          ) : (
            <ActivityForm
              initialValues={data.activity}
              onSubmit={this.onSubmit}
            />
          )}
          <button
            style={{marginTop: '20px'}}
            className="btn btn-danger"
            onClick={this.onDeleteConfirm}
          >
            Delete
          </button>
        </div>
      </div>
    )
  }
}

export default compose(
  graphql(GET_ACTIVITY_QUERY, {
    options: ({match}) => ({ variables: { id: match.params.id } })
  }),
  graphql(EDIT_ACTIVITY_MUTATION, { name: 'editMutate' }),
  graphql(DELETE_ACTIVITY_MUTATION, { name: 'deleteMutate' })
)(Edit)
