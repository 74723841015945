import React, { Component } from 'react'
import DateTime from 'react-datetime'
import moment from 'moment'

type Props = {
  field: {
    onChange: Function,
    onBlur: Function,
    name: string,
    value: string
  },
  form?: Object,
  id: string,
  inputClassName?: string,
  client: Object
}

type State = {}

export default class DateTimePicker extends Component<Props, State> {
  onChange = (value) => {
    const { form, field } = this.props
    if (value && typeof value !== 'string') {
      form.setFieldValue(field.name, value)
    } else if (value === '') {
      form.setFieldValue(field.name, null)
    }
  }

  render () {
    const { field, form, inputClassName } = this.props
    const {onChange, onBlur, name} = field

    const inputProps = {
      className: inputClassName
    }
    return (
      <DateTime
        inputProps={inputProps}
        onChange={this.onChange}
        value={typeof field.value === 'string' ? moment(field.value) : field.value}
      />
    )
  }
}
