import moment from 'moment'

export class DateUtils {
  static getHumanFormatDuration (ms: number) {
    const duration = moment.duration(ms)
    let returnString = '' + Math.floor(duration.asHours())
    if (returnString.length === 1) {
      returnString = '0' + returnString
    }
    return returnString + 'h' + moment.utc(ms).format(":mm[m]")
  }
}
