import React, { Component } from 'react'
import { withApollo } from 'react-apollo'
import AutoSuggest from 'react-autosuggest'

type Props = {
  id: string,
  inputClassName?: string,
  client: Object,
  onFilter: Function,
  filter?: {
    value: any
  },
  inputValue: string,
  inputOnChange: Function,
  getSuggestionValue: Function,
  renderSuggestion: Function,
  query: any,
  filterKeys: Array<string>,
  resultsKey: string
}

type State = {
  suggestions: Array
}

class RemoteAutocompleteFilter extends Component<Props, State> {
  state = {
    suggestions: []
  }

  onSuggestionsFetchRequested = async ({ value }) => {
    const { client, query, filterKeys, resultsKey } = this.props
    const result = await client.query({
      query: query,
      variables: {
        filters: filterKeys.reduce((acc, filterKey) => {
          acc[filterKey] = value
          return acc
        }, {})
      },
      fetchPolicy: 'no-cache'
    })
    this.setState({
      suggestions: result.data[resultsKey].data
    })
  }

  onSuggestionsClearRequested = () => {
    this.setState({suggestions: []})
  }

  onChange = (event, { newValue, method }) => {
    const { onFilter, inputOnChange } = this.props
    inputOnChange(event, {newValue, method})

    if ((newValue === '' || !newValue) && onFilter) {
      onFilter({ value: null })
    }
  }

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    const { onFilter } = this.props
    if (onFilter) {
      onFilter({value: suggestion })
    }
  }

  render () {
    const { suggestions } = this.state
    const {
      inputClassName,
      inputValue,
      getSuggestionValue,
      renderSuggestion,
      ...props
    } = this.props

    return (
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={this.onSuggestionSelected}
        inputProps={{
          value: inputValue,
          onChange: this.onChange,
          className: inputClassName
        }}
        theme={{
          container: 'autosuggest',
          input: 'form-control',
          suggestionsContainer: 'dropdown',
          suggestionsList: `dropdown-menu ${suggestions.length ? 'show' : ''}`,
          suggestionHighlighted: 'text-dark'
        }}
        {...props}
      />
    )
  }
}

export default withApollo(RemoteAutocompleteFilter)
