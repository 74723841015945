import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

export const ProjectFormatter = ({value: {color, name}}) => (
  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
    <div
      style={{
        width: '20px',
        minWidth: '20px',
        height: '20px',
        marginRight: '12px',
        marginLeft: '8px',
        backgroundColor: color
      }}
    />
    {`${name}`}
  </div>
)

const ProjectTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={ProjectFormatter}
    {...props}
  />
)

export default ProjectTypeProvider
