import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import history from '../../../../history'

export const ActivityActionFormatter = ({ value }) => (
  <div style={{paddingLeft: '5px', paddingRight: '5px'}}>
    <button
      onClick={() => { history.push(`/timesheets/activities/edit/${value.id}`) }}
      type="button"
      className="btn btn-light"
    >
      <ion-icon name="md-create" />
    </button>
  </div>
)

const ActivityActionTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={ActivityActionFormatter}
    {...props}
  />
)

export default ActivityActionTypeProvider
