import React, { Component } from 'react'
import gql from 'graphql-tag'
import RemoteAutocompleteFilter from './RemoteAutocompleteFilter'

const GET_PROJECTS_QUERY = gql`query GetProjects($filters: ProjectFilterInput) {
    projects(filters: $filters) {
        data {
            id
            name
            color
        }
    }
}`

type Props = {
  id: string,
  inputClassName?: string,
  onFilter?: Function,
  filter: {
    value: any
  },
  inputValue: string,
  inputOnChange: Function,
}

class ProjectAutocompleteFilter extends Component<Props, State> {
  getSuggestionValue = (suggestion) => {
    return suggestion.name
  }

  renderSuggestion = (suggestion) => {
    return (
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', margin: 10}}>
        <div style={{
          minWidth: '15px',
          width: '15px',
          height: '15px',
          marginRight: '8px',
          // marginLeft: '8px',
          backgroundColor: suggestion.color
        }} />

        {`${suggestion.name}`}
      </div>
    )
  }

  render () {
    const {id, onFilter, inputOnChange, inputClassName, inputValue, filter} = this.props
    return (
      <RemoteAutocompleteFilter
        id={id}
        onFilter={onFilter}
        inputValue={inputValue}
        inputOnChange={inputOnChange}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        query={GET_PROJECTS_QUERY}
        filterKeys={['name']}
        resultsKey="projects"
        inputClassName={inputClassName}
        filter={filter}
      />
    )
  }
}

export default ProjectAutocompleteFilter
