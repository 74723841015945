import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import { DateUtils } from '../../../../utils/date'
import { ActivityUtils } from '../../../../utils/activity'
import Timer from '../../../Timer'

export const DurationStringFormatter = ({ value }) => {
  if (!value || !value.durationMilliseconds) {
    return null
  }

  if (value.end) {
    return (
      <b>
        {
          DateUtils
            .getHumanFormatDuration(
              value.durationMilliseconds -
              ActivityUtils.calculateTotalPauses(value.pauses)
            )
        }
      </b>
    )
  } else {
    return (
      <b>
        <Timer
          totalPausesDuration={
            value.pauses.length > 0
              ? ActivityUtils.calculateTotalPauses(value.pauses)
              : 0
          }
          stopDate={(
            value.pauses.length > 0 &&
            !value.pauses[0].end
          ) ? value.pauses[0].start : null}
          startDate={value.start}
        />
      </b>
    )
  }
}

const DurationTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={DurationStringFormatter}
    {...props}
  />
)

export default DurationTypeProvider
