import React, { Component } from 'react'
import gql from 'graphql-tag'
import { compose, graphql } from 'react-apollo'
import ProjectForm from '../components/ProjectForm'
import history from '../../../../../../history'

const CREATE_PROJECT_MUTATION = gql`mutation CreateProject($data: ProjectInput) {
    createProject(data: $data) {
        id
        name
    }
}`

type Props = {
  mutate: Function
}

class Create extends Component<Props> {
  constructor (props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
  }

  async onSubmit (values, { setSubmitting }) {
    try {
      const result = await this.props.mutate({
        variables: {
          data: {
            name: values.name,
            color: values.color
          }
        }
      })
      history.goBack()
    } catch (e) {
      console.error('errore', e)
    }
  }

  render () {
    return (
      <div className="col-12">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Crea Progetto</h1>
        </div>
        <div className="col-8 offset-2">
          <ProjectForm
            onSubmit={this.onSubmit}
          />
        </div>
      </div>
    )
  }
}

export default compose(
  graphql(CREATE_PROJECT_MUTATION)
)(Create)
