import React, {Component} from 'react'
import { DateRangePicker } from 'react-dates'

type Props = {
  filter: {
    value: any
  },
  onFilter?: Function,
  startDateId: string,
  endDateId: string,
}

type State = {
  focusedInput: any
}

export default class DateRangeFilter extends Component<Props, State> {
  state = {
    focusedInput: null
  }

  render () {
    const { filter, onFilter, startDateId, endDateId, ...props } = this.props
    const { focusedInput } = this.state
    const startDate = filter && filter.value ? filter.value.startDate : null
    const endDate = filter && filter.value ? filter.value.endDate : null
    return (
      <DateRangePicker
        {...props}
        startDate={startDate} // momentPropTypes.momentObj or null,
        startDateId={startDateId} // PropTypes.string.isRequired,
        endDate={endDate} // momentPropTypes.momentObj or null,
        endDateId={endDateId} // PropTypes.string.isRequired,
        onDatesChange={(range) => onFilter({value: range})} // PropTypes.func.isRequired,
        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
      />
    )
  }
}
