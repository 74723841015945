// @flow
import React, { Component } from 'react'
import 'react-dates/initialize'
import { createClient } from './client'
import AuthService from './services/AuthService'
import { ApolloProvider } from 'react-apollo'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import PrivateRoute from './core/Router/PrivateRoute'
import Login from './views/Login'
import history from './history'
import TimeSheetsManager from './views/TimeSheetsManager'
import moment from 'moment'
import 'moment/locale/it'

import 'open-iconic/font/css/open-iconic-bootstrap.css'
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'react-datetime/css/react-datetime.css'
import "react-table/react-table.css"
import 'react-dates/lib/css/_datepicker.css'
import './App.css'

moment.locale('it')

type Props = {}
type State = {
  initialized: boolean
}

const client = createClient()

class App extends Component<Props, State> {
  constructor (props) {
    super(props)

    this.state = {
      initialized: false
    }
  }

  componentDidMount () {
    AuthService.instance.init(client, history)
    this.setState({
      initialized: true
    })
  }

  render() {
    const {initialized} = this.state
    if (!initialized) {
      return (
        <div>Caricamento in corso...</div>
      )
    }

    return (
      <ApolloProvider client={client}>
        <Router history={history}>
          <Switch>
            <PrivateRoute path="/timesheets" component={TimeSheetsManager} />
            <Route path="/login" component={Login} />
            <Redirect to="/timesheets" />
          </Switch>
        </Router>
      </ApolloProvider>
    )
  }
}

export default App
