import React, { Component } from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import AuthService from '../../services/AuthService'
import { Redirect } from 'react-router-dom'

import './Login.css'

type Props = {}
type State = {
  redirectToHome: boolean
}

export default class Login extends Component<Props, State> {
  constructor (props) {
    super(props)
    this.state = {
      redirectToHome: false
    }

    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount () {
    if (AuthService.instance.isUserAuthenticated()) {
      this.setState({redirectToHome: true})
    }
  }

  generateValidationSchema () {
    return Yup.object().shape({
      email: Yup
        .string()
        .email('Mail non valida')
        .required('Inserisci una mail'),
      password: Yup
        .string()
        .min(3, 'Password troppo corta')
        .required('Inserisci una password')
    })
  }

  onSubmit = async (values, actions) => {
    try {
      const result = await AuthService.instance.login(
        values.email,
        values.password
      )
      this.setState({redirectToHome: true})
      actions.setSubmitting(false)
    } catch (e) {
      console.error(e)
      actions.setErrors({
        submit: 'E-mail o password errati'
      })
      actions.setSubmitting(false)
    }
  }

  render () {
    const {redirectToHome} = this.state
    if (redirectToHome) {
      return (
        <Redirect to="/admin" />
      )
    }

    return (
      <Formik
        validationSchema={this.generateValidationSchema()}
        onSubmit={this.onSubmit}
      >
        {({
          errors,
          touched,
          isSubmitting
        }) => (
          <div className="Login text-center">
            <Form className="Login__form-signin">

              {/* <img
                src={require('../../assets/vatlynx.jpg')}
                className="mb-4"
                alt="Vatlynx Admin Login"
                height={72}
              /> */}
              <h1 className="h3 mb-3 font-weight-normal">
                TimeSheet Login
              </h1>
              <label
                htmlFor="Login__email"
                className="sr-only"
              >
                Email address
              </label>
              <Field
                id="Login__email"
                className="form-control"
                name="email"
                type="email"
                placeholder="E-mail"
              />
              <label
                htmlFor="Login__password"
                className="sr-only"
              >
                Indirizzo e-mail
              </label>
              <Field
                id="Login__password"
                className="form-control"
                name="password"
                type="password"
                placeholder="Password"
              />
              <div className="checkbox mb-3">
                <label>
                  <Field
                    name="rememberMe"
                    type="checkbox"
                  /> Ricordami
                </label>
              </div>

              {errors.email && touched.email && (
                <div className="mt-2 mb-2 text-danger">
                  {errors.email}
                </div>
              )}
              {errors.password && touched.password && (
                <div className="mt-2 mb-2 text-danger">
                  {errors.password}
                </div>
              )}
              {errors.submit && (
                <div className="mt-2 mb-2 text-danger">
                  {errors.submit}
                </div>
              )}
              <button
                className="btn btn-lg btn-primary btn-block"
                type="submit"
                disabled={isSubmitting}
              >
                Login
              </button>
              <p className="mt-5 mb-3 text-muted">&copy; 2018-2019</p>
            </Form>
          </div>
        )}
      </Formik>
    )
  }
}
