import React, { Component } from 'react'
import gql from 'graphql-tag'
import RemoteAutocompleteFilter from './RemoteAutocompleteFilter'

const GET_USERS_QUERY = gql`query GetUsers($filters: UserFilterInput) {
    users(filters: $filters) {
        data {
            id
            firstName
            lastName
            email
            phone
        }
    }
}`

type Props = {
  id: string,
  inputClassName?: string,
  onFilter?: Function,
  filter: {
    value: any
  },
  inputValue: string,
  inputOnChange: Function,
}

class UserAutocompleteFilter extends Component<Props, State> {
  getSuggestionValue = (suggestion) => {
    return suggestion.firstName + ' ' + suggestion.lastName
  }

  renderSuggestion = (suggestion) => {
    return (
      <div style={{margin: '10px', fontWeight: 'normal'}}>
        {`${suggestion.firstName} ${suggestion.lastName}`}
      </div>
    )
  }

  render () {
    const {id, onFilter, inputOnChange, inputClassName, inputValue, filter} = this.props
    return (
      <RemoteAutocompleteFilter
        id={id}
        onFilter={onFilter}
        inputValue={inputValue}
        inputOnChange={inputOnChange}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        query={GET_USERS_QUERY}
        filterKeys={['fullName']}
        resultsKey="users"
        inputClassName={inputClassName}
        filter={filter}
      />
    )
  }
}

export default UserAutocompleteFilter
