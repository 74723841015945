import React, { Component } from 'react'
import { NavLink, Redirect, Switch } from 'react-router-dom'
import { Route } from 'react-router-dom'
import List from './scenes/List'
import CalendarView from './scenes/Calendar'
import { ButtonGroup } from 'reactstrap'

type Props = {
  match: any
}

export default class Main extends Component<Props> {
  render () {
    const {match} = this.props
    return (
      <React.Fragment>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Activity</h1>

          <div className="btn-toolbar mb-2 mb-md-0">
            <ButtonGroup size="sm" style={{marginRight: '10px'}}>
              <NavLink
                className="btn btn-light"
                to={`${match.url}/list`}
              >
                Tabella
              </NavLink>
              <NavLink
                className="btn btn-light"
                to={`${match.url}/calendar`}
              >
                Calendario
              </NavLink>
            </ButtonGroup>
            <NavLink to={`${match.url}/create`} className="btn btn-sm btn-outline-secondary">New Activity</NavLink>
          </div>
        </div>
        <Switch>
          <Route path={`${match.url}/list`} component={List} />
          <Route path={`${match.url}/calendar`} component={CalendarView} />
          <Redirect to={`${match.url}/list`} />
        </Switch>
      </React.Fragment>
    )
  }
}
