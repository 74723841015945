import React, { Component } from 'react'
import gql from 'graphql-tag'
import { compose, graphql } from 'react-apollo'
import ReactTable from 'react-table'
import { NavLink } from 'react-router-dom'
import history from '../../../../../../history'

const GET_USERS_QUERY = gql`query GetUsers(
    $pagination: PaginationInput, 
    $filters: UserFilterInput, 
    $sort: UserSortInput
) {
    users (pagination: $pagination, filters: $filters, sort: $sort) {
        data {
            id
            firstName
            lastName
            email
            phone
        }
        limit
        offset
        total
    }
}`

type Props = {
  data: any,
  match: any
}
type State = {
  columns: Array
}

class List extends Component<Props, State> {
  constructor (props) {
    super(props)
    this.state = {
      columns: [
        {
          Header: 'ID',
          accessor: 'id',
          sortable: true,
          filterable: true,
          width: 100
        },
        {
          Header: 'Nome',
          accessor: 'firstName',
          sortable: true,
          filterable: true
        },
        {
          Header: 'Cognome',
          accessor: 'lastName',
          sortable: true,
          filterable: true
        },
        {
          Header: 'E-mail',
          accessor: 'email',
          sortable: true,
          filterable: true
        },
        {
          Header: 'Telefono',
          accessor: 'phone',
          sortable: true,
          filterable: true
        }
      ]
    }

    this.onFetchData = this.onFetchData.bind(this)
    this.onRowClick = this.onRowClick.bind(this)
  }

  onFetchData (state) {
    const { data } = this.props
    const filterObj = {}

    state.filtered.forEach((f) => {
      let key = f.id

      if (key.split('.').length > 1) {
        key = key.split('.')[1]
      }

      filterObj[key] = f.value
    })

    let sortObj = {}
    state.sorted.forEach(s => {
      sortObj[s.id] = s.desc ? 'DESC' : 'ASC'
    })

    data.refetch({
      pagination: {
        limit: state.pageSize,
        offset: state.page
      },
      filters: filterObj,
      sort: sortObj
    })
  }

  getTotalPages () {
    const { data } = this.props
    const { users } = data

    if (users.total !== 0) {
      return Math.ceil(users.total / users.limit)
    }

    return 0
  }

  onRowClick (row) {
    const {match} = this.props
    history.push(`${match.url}/edit/${row.id}`)
  }

  render () {
    const { data, match } = this.props
    const {columns} = this.state

    if (!data || !data.users) {
      return (
        <div>Caricamento in corso...</div>
      )
    }

    return (
      <div className="col-12">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Users</h1>

          <div className="btn-toolbar mb-2 mb-md-0">
            <NavLink to={`${match.url}/create`} className="btn btn-sm btn-outline-secondary">New User</NavLink>
          </div>
        </div>
        <ReactTable
          style={{maxHeight: '500px'}}
          manual
          filterable
          loading={data.loading}
          columns={columns}
          data={data.users.data}
          getTrProps={(state, rowInfo, column, instance) => ({
            onClick: () => this.onRowClick(rowInfo.original)
          })}
          pages={this.getTotalPages()}
          className="-highlight"
          onFetchData={this.onFetchData}
          noDataText="No Users"
          defaultPageSize={20}
          nextText="Next"
          previousText="Previous"
          loadingText="Loading..."
          rowsText="users"
          ofText="of"
          pageText="Page"
        />
      </div>
    )
  }
}

export default compose(
  graphql(GET_USERS_QUERY)
)(List)
