import React, { Component } from 'react'
import { Field } from 'formik'
import { SketchPicker } from 'react-color'
import { Popover, Button } from 'reactstrap'

type Props = {
  field: {
    onChange: Function,
    onBlur: Function,
    name: string,
    value: string
  },
  form?: Object,
  id: string
}

type State = {
  isColorPickerVisible: boolean
}

export default class ProjectAutocomplete extends Component<Props, State> {
  state: State = {
    isColorPickerVisible: false
  }

  toggleShowPicker = () => {
    const { showColorPicker } = this.state
    this.setState({isColorPickerVisible: !showColorPicker})
  }

  showColorPicker = () => {
    this.setState({isColorPickerVisible: true})
  }

  hideColorPicker = () => {
    this.setState({isColorPickerVisible: false})
  }

  render () {
    const { isColorPickerVisible } = this.state
    const { field, form, inputClassName, id } = this.props
    const {onChange, onBlur, name, value} = field
    return (
      <React.Fragment>
        <input
          value={value || '#ffffff'}
          onChange={onChange}
          autoComplete="off"
          id={id}
          type="text"
          className="form-control"
        />
        <Popover
          trigger="focus"
          isOpen={isColorPickerVisible}
          target={id}
          toggle={this.toggleShowPicker}
        >
          <SketchPicker
            onChangeComplete={(color) => form.setFieldValue(name, color.hex)}
            disableAlpha
            presetColors={[
              '#D0021B',
              '#F5A623',
              '#F8E71C',
              '#8B572A',
              '#7ED321',
              '#417505',
              '#BD10E0',
              '#9013FE',
              '#4A90E2',
              '#50E3C2',
              '#B8E986'
            ]}
            color={value || '#ffffff'}
          />
          <Button color="primary" block onClick={this.hideColorPicker}>CHIUDI</Button>
        </Popover>
      </React.Fragment>
    )
  }
}
