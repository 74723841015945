import React, { Component } from 'react'
import gql from 'graphql-tag'
import { withApollo } from 'react-apollo'
import history from '../../../../../../../../history'
import moment from 'moment'
import './Calendar.css'
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'
import {ColorUtils} from '../../../../../../../../utils/color'
import { ArrayUtils } from '../../../../../../../../utils/array'
import IosArrowForward from 'react-ionicons/lib/IosArrowForward'
import IosArrowBack from 'react-ionicons/lib/IosArrowBack'
import { Field, Form, Formik } from 'formik'
import UserAutocomplete from '../../../../../../../../core/FormFields/UserAutocomplete'
import ProjectAutocomplete from '../../../../../../../../core/FormFields/ProjectAutocomplete'
import ButtonGroup from 'reactstrap/es/ButtonGroup'
import { Button } from 'reactstrap'
import { downloadCSV } from '../../../../../../../../utils/download'
import AuthService from '../../../../../../../../services/AuthService'
import fileDownload from 'js-file-download'

const GET_PROJECTS_QUERY = gql`query GetActivities(
$pagination: PaginationInput,
$filters: ActivityFilterInput,
$sort: ActivitySortInput
) {
    activities (pagination: $pagination, filters: $filters, sort: $sort) {
        data {
            id
            description
            start
            end
            durationMilliseconds
            project {
                id
                name
                color
            }
            pauses {
                id
                description
                start
                end
                durationMilliseconds
            }
            user {
                id
                firstName
                lastName
                email
                phone
            }
        }
        limit
        offset
        total
    }
}`

const GroupBy = {
  user: 'user',
  project: 'project'
}

type DayOfMonth = {
  start: Object,
  end: Object,
  activities: Array
}

type Props = {
  client: any,
  match: any
}
type State = {
  timelineData: Object,
  timelineSelectedMonth: any,
  daysOfMonth: Array<DayOfMonth>,
  hoursOfDay: Array,
  loading: boolean,
  groupBy: string,
  maxActivityLength: number,
  totalMonthHours: number,
  selectedProject: any,
  selectedUser: any
}

class Calendar extends Component<Props, State> {
  constructor (props) {
    super(props)
    this.state = {
      daysOfMonth: [],
      hoursOfDay: [],
      timelineData: {},
      timelineSelectedMonth: moment().startOf('month'),
      loading: true,
      groupBy: GroupBy.user,
      maxActivityLength: 0,
      totalMonthHours: 0,
      selectedProject: null,
      selectedUser: null
    }
  }

  async componentDidMount () {
    const { timelineSelectedMonth } = this.state
    this.setState({loading: true})
    await this.initMonthDates(timelineSelectedMonth)
    await this.initHoursOfDay()
    await this.fetchActivities()
    this.setState({loading: false})
  }

  onExportClick = async () => {
    const { selectedUser, selectedProject, timelineSelectedMonth } = this.state
    const filters = {}

    filters.betweenDateStart = moment(timelineSelectedMonth).startOf('month').format()
    filters.betweenDateEnd = moment(timelineSelectedMonth).endOf('month').format()
    filters.roundForExcess = true

    if (selectedUser) {
      filters.user = selectedUser.id
    }
    if (selectedProject) {
      filters.project = selectedProject.id
    }

    const result = await downloadCSV('/download/monthlyReport', AuthService.instance.token, filters)
    fileDownload(result, 'monthly-report.csv')
  }

  initMonthDates = async (date) => {
    return new Promise(resolve => {
      let dates = []
      const startOfMonth = moment(date).startOf('month')
      const endOfMonth = moment(date).endOf('month')
      const numberOfDays = endOfMonth.diff(startOfMonth, 'day')
      for (let i = 0; i <= numberOfDays; i++) {
        dates.push({
          start: moment(startOfMonth).add(i, 'days').startOf('day'),
          end: moment(startOfMonth).add(i, 'days').endOf('day'),
        })
      }
      this.setState({
        daysOfMonth: dates
      }, resolve)
    })
  }

  initHoursOfDay = async () => {
    return new Promise(resolve => this.setState({hoursOfDay: Array.from(Array(24).keys())}, resolve))
  }

  fetchActivities = async () => {
    const { client } = this.props
    const { timelineSelectedMonth, daysOfMonth, selectedProject, selectedUser } = this.state
    const result = await client.query({
      query: GET_PROJECTS_QUERY,
      variables: {
        pagination: {
          limit: 1000,
          offset: 0
        },
        filters: {
          betweenDateStart: moment(timelineSelectedMonth).startOf('month').format(),
          betweenDateEnd: moment(timelineSelectedMonth).endOf('month').format(),
          project: selectedProject ? selectedProject.id : null,
          user: selectedUser ? selectedUser.id : null
        },
        sort: {
          start: 'ASC'
        }
      },
      fetchPolicy: 'no-cache'
    })

    let maxActivityLength = 0
    let totalMonthMilliseconds = 0
    const newDaysOfMonths = daysOfMonth.map((day: DayOfMonth) => {
      let activities = result.data.activities.data.filter(activity => {
        const activityStartDate = moment(activity.start)
        const activityEndDate = moment(activity.end)

        // check if activity is in Day
        return day.start.isBetween(activityStartDate, activityEndDate, null, '[]') ||
          day.end.isBetween(activityStartDate, activityEndDate, null, '[]') ||
          activityStartDate.isBetween(day.start, day.end)

      }).map(activity => {
        const newActivity = {
          ...activity
        }

        const activityStartDate = moment(activity.start)
        const activityEndDate = moment(activity.end)

        if (activityStartDate.isBefore(day.start)) {
          newActivity.start = moment(day.start).format()
          newActivity.durationMilliseconds = activityEndDate.diff(moment(newActivity.start))
        }

        if (activityEndDate.isAfter(day.end)) {
          newActivity.end = moment(day.end).format()
          newActivity.durationMilliseconds = moment(newActivity.end).diff(newActivity.start)
        }

        totalMonthMilliseconds += newActivity.durationMilliseconds

        newActivity.pauses = activity.pauses.filter(pause => {
          const pauseStartDate = moment(pause.start)
          const pauseEndDate = moment(pause.end)

          // check if pause is in Day
          return day.start.isBetween(pauseStartDate, pauseEndDate, null, '[]') ||
            day.end.isBetween(pauseStartDate, pauseEndDate, null, '[]') ||
            pauseStartDate.isBetween(day.start, day.end)

        }).map(pause => {
          const newPause = {
            ...pause
          }

          const pauseStartDate = moment(pause.start)
          const pauseEndDate = moment(pause.end)

          if (pauseStartDate.isBefore(day.start)) {
            newPause.start = moment(day.start).format()
            newPause.durationMilliseconds = pauseEndDate.diff(moment(newPause.start))
          }

          if (pauseEndDate.isAfter(day.end)) {
            newPause.end = moment(day.end).format()
            newPause.durationMilliseconds = moment(newPause.end).diff(newPause.start)
          }

          totalMonthMilliseconds -= newPause.durationMilliseconds
          return newPause
        })
        return newActivity
      })

      activities = activities.filter((item, index, self) => index === self.indexOf(activities.filter(sItem => sItem.start === item.start && sItem.end === item.end && sItem.user.id === item.user.id)[0]))

      const groupedActivities = ArrayUtils.groupBy(activities, this.state.groupBy)

      if (Object.keys(groupedActivities).length > maxActivityLength) {
        maxActivityLength = Object.keys(groupedActivities).length
      }

      return {
        ...day,
        activities: activities
      }
    })

    const totalMonthHours = moment.duration(totalMonthMilliseconds).asHours()
    this.setState({
      daysOfMonth: newDaysOfMonths,
      maxActivityLength: maxActivityLength,
      totalMonthHours: totalMonthHours
    })
    console.log(maxActivityLength, this.state.maxActivityLength * 35, 'massima lunghezza')
  }

  onPreviousMonthSelected = () => {
    const { timelineSelectedMonth } = this.state
    const prevMonth = moment(timelineSelectedMonth).subtract(1, 'months')
    this.setState({
      timelineSelectedMonth: prevMonth,
      loading: true
    }, async () => {
      await this.initMonthDates(prevMonth)
      await this.fetchActivities()
      this.setState({loading: false})
    })
  }

  onNextMonthSelected = () => {
    const { timelineSelectedMonth } = this.state
    const nextMonth = moment(timelineSelectedMonth).add(1, 'months')
    this.setState({
      timelineSelectedMonth: nextMonth,
      loading: true
    }, async () => {
      await this.initMonthDates(nextMonth)
      await this.fetchActivities()
      this.setState({loading: false})
    })
  }

  onProjectFilterChange = (value) => {
    console.log(value)
    this.setState({selectedProject: value}, async () => {
      await this.fetchActivities()
    })
  }

  onUserFilterChange = (value) => {
    console.log(value)
    this.setState({selectedUser: value}, async () => {
      await this.fetchActivities()
    })
  }

  changeGroupBy = (groupBy) => {
    const { timelineSelectedMonth } = this.state
    this.setState({ groupBy: groupBy, loading: true }, async () => {
      await this.initMonthDates(timelineSelectedMonth)
      await this.fetchActivities()
      this.setState({loading: false})
    })
  }

  render () {
    const { daysOfMonth, hoursOfDay, timelineSelectedMonth, groupBy, loading, totalMonthHours } = this.state

    return (
      <div>
        <div id="calendar-timeline-header">
          <h4>
            <IosArrowBack onClick={this.onPreviousMonthSelected} />
            {timelineSelectedMonth.format('MMMM YYYY')}
            <IosArrowForward onClick={this.onNextMonthSelected} />
          </h4>

          <Formik
            onSubmit={(values) => {}}
          >
            {({ values }) => (
              <Form className="form-inline" style={{marginLeft: 20}}>
                <div className="form-group mb-2">
                  <label htmlFor="userCalendar" style={{marginRight: 10}}>Utente: </label>
                  <Field
                    name="userCalendar"
                    id="userCalendar"
                    component={UserAutocomplete}
                    inputClassName="form-control"
                    onChange={this.onUserFilterChange}
                  />
                </div>
                <div className="form-group mb-2" style={{marginLeft: 20}}>
                  <label htmlFor="calendarProject" style={{marginRight: 10}}>Progetto: </label>
                  <Field
                    name="calendarProject"
                    id="calendarProject"
                    component={ProjectAutocomplete}
                    inputClassName="form-control"
                    onChange={this.onProjectFilterChange}
                  />
                </div>
              </Form>
            )}
          </Formik>

          <ButtonGroup size="sm">
            <Button onClick={this.onExportClick} color="light">Esporta Report Mensile</Button>
          </ButtonGroup>
          <ButtonGroup size="sm">
            <Button onClick={() => this.changeGroupBy(GroupBy.user)} active={groupBy === GroupBy.user} color="light">Utenti</Button>
            <Button onClick={() => this.changeGroupBy(GroupBy.project)} active={groupBy === GroupBy.project} color="light">Progetti</Button>
          </ButtonGroup>
        </div>
        {loading ? (
          <div>Caricamento in corso...</div>
        ) : (
          <div id="calendar-timeline">
            <ScrollSync>
              <div>
                {daysOfMonth.map((day: DayOfMonth, dayIndex) => {
                  const groupedActivities = ArrayUtils.groupBy(day.activities, groupBy)
                  return (
                    <div
                      key={`day-of-month-${dayIndex}`}
                      className="calendar-timeline-container"
                      style={{
                        height: (this.state.maxActivityLength * 25 + 20 < 100 ? 100 : (this.state.maxActivityLength * 25) + 30) + 'px'
                      }}
                    >
                      <div
                        style={
                          dayIndex === 0
                            ? {borderTop: 0}
                            : null
                        }
                        className="calendar-timeline-day-outer-container"
                      >
                        <div className="calendar-timeline-day-container">
                          <div style={{fontSize: '2rem'}} className="display-4">{day.start.format('DD')}</div>
                          <div>{day.start.format('dddd')}</div>
                        </div>
                      </div>
                      <div
                        style={
                          dayIndex === 0
                            ? {borderTop: 0}
                            : null
                        }
                        className="calendar-timeline-group-info"
                      >
                        {Object.keys(groupedActivities).map((key, index) => {
                          const groupedObject = groupedActivities[key][0][groupBy]
                          return (
                            <div
                              key={`activity-${index}`}
                              style={{
                                position: 'absolute',
                                top: ((index * 25) + 25) + 'px',
                                height: '20px',
                                right: 0,
                                left: 10,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                paddingRight: 10,
                                paddingLeft: 10,
                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                borderTopLeftRadius: 5,
                                borderBottomLeftRadius: 5,
                                textOverflow: 'clip',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden'
                              }}
                            >
                              {groupBy === GroupBy.user && `${groupedObject.firstName} ${groupedObject.lastName}`}
                              {groupBy === GroupBy.project && `${groupedObject.name}`}
                            </div>
                          )
                        })}
                      </div>
                      <ScrollSyncPane>
                        <div
                          className="calendar-timeline-hours-container"
                          style={
                            dayIndex === 0
                              ? {borderTop: 0}
                              : null
                          }
                        >
                          {hoursOfDay.map((hour, hourIndex) => {
                            return (
                              <div
                                className="calendar-timeline-hour"
                                key={`timeline-hour-${hourIndex}`}
                                style={
                                  hourIndex === 0
                                    ? {borderLeft: 0}
                                    : null
                                }
                              >
                                {hour}
                              </div>
                            )
                          })}
                          <div className="calendar-total">
                            {Object.keys(groupedActivities).map((key, index) => {
                              const groupedObject = groupedActivities[key]
                              let totalDuration = 0
                              groupedObject.map(item => {
                                totalDuration += item.durationMilliseconds
                                item.pauses.map(pause => {
                                  totalDuration -= pause.durationMilliseconds
                                })
                              })
                              const totalHours = moment.duration(totalDuration).asHours()
                              return (
                                <div
                                  key={`activities-total-${index}`}
                                  style={{
                                    position: 'absolute',
                                    top: ((index * 25) + 25) + 'px',
                                    height: '20px',
                                    left: 0,
                                    right: 10,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    paddingRight: 10,
                                    paddingLeft: 10,
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                    borderTopRightRadius: 5,
                                    borderBottomRightRadius: 5
                                  }}
                                >
                                  <strong>{Number(totalHours).toLocaleString("it-IT", {maximumFractionDigits: 2})}h</strong>
                                </div>
                              )
                            })}
                          </div>
                          {Object.keys(groupedActivities).map((groupedKey, groupedActivitiesIndex) => {
                            const userActivities = groupedActivities[groupedKey]
                            return (<div
                              key={`grouped-activities-${groupedActivitiesIndex}`}
                              style={{
                                position: 'absolute',
                                top: ((groupedActivitiesIndex * 25) + 25) + 'px',
                                height: '20px',
                                display: 'flex',
                                left: 0,
                                width: 'calc(24 * 60px)',
                                backgroundColor: 'rgba(0, 0, 0, 0.1)'
                              }}
                            >
                              {userActivities.map((activity, activityIndex) => {
                                const start = moment(activity.start)
                                return (
                                  <React.Fragment key={`activity-${activity.id}`}>
                                    <div
                                      style={{
                                        position: 'absolute',
                                        height: '20px',
                                        backgroundColor: activity.project.color,
                                        left: (start.hours() * 60 + start.minutes()) + 'px',
                                        width: (60 * moment.duration(activity.durationMilliseconds).asHours()) + 'px',
                                        color: ColorUtils.invertColor(activity.project.color, true),
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        textOverflow: 'clip',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        paddingLeft: 7,
                                        borderTopLeftRadius: 5,
                                        borderTopRightRadius: 5,
                                        borderBottomRightRadius: 5,
                                        borderBottomLeftRadius: 5
                                      }}
                                    >
                                      {groupBy === GroupBy.project && `${activity.user.firstName} ${activity.user.lastName}`}
                                      {groupBy === GroupBy.user && `${activity.project.name}`}
                                    </div>
                                    {activity.pauses.map((pause, pauseIndex) => {
                                      const pauseStart = moment(pause.start)
                                      return (
                                        <div
                                          key={`activity-pause-${activity.id}-${pauseIndex}`}
                                          style={{
                                            position: 'absolute',
                                            height: '20px',
                                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                            left: (pauseStart.hours() * 60 + pauseStart.minutes()) + 'px',
                                            width: (60 * moment.duration(pause.durationMilliseconds).asHours()) + 'px',
                                            borderTopLeftRadius: 5,
                                            borderTopRightRadius: 5,
                                            borderBottomRightRadius: 5,
                                            borderBottomLeftRadius: 5
                                          }}
                                        />
                                      )
                                    })}
                                  </React.Fragment>
                                )
                              })}
                            </div>)
                          })}
                        </div>
                      </ScrollSyncPane>
                    </div>
                  )
                })}
              </div>
            </ScrollSync>
          </div>
        )}
        <div id="calendar-timeline-footer">
          <div className="calendar-timeline-total-container">
            <div>
              <div className="display-4 month-total-hours">
                {Number(totalMonthHours).toLocaleString("it-IT", {maximumFractionDigits: 2})}h
              </div>
              <div className="text-center text-muted">Ore Totali</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withApollo(Calendar)
