import React, { Component } from 'react'
import { Field, Form, Formik } from 'formik'
import ColorSelector from '../../../../../../core/FormFields/ColorSelector'

type Props = {
  onSubmit: Function,
  initialValues: Object,
  submitLabel: string
}

export default class ProjectForm extends Component {
  render () {
    const { onSubmit, initialValues, submitLabel } = this.props
    return (
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        <Form>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="name">Name</label>
              <Field
                type="text"
                name="name"
                id="name"
                className="form-control"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="color">Color</label>
              <Field
                component={ColorSelector}
                name="color"
                id="color"
                className="form-control"
              />
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-lg btn-primary"
          >
            {submitLabel || 'Save'}
          </button>
        </Form>
      </Formik>
    )
  }
}
