import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

export const ActivityStatusFormatter = ({ value }) => (
  <div style={{paddingLeft: '5px', paddingRight: '5px'}}>
    <span
      style={{
        color: value ? '#57d500' : '#ffbf00',
        marginRight: '8px',
        fontSize: '11px'
      }}
    >
      ●
    </span>
    {value ? 'Completato' : 'In Corso'}
  </div>
)

const ActivityStatusTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={ActivityStatusFormatter}
    {...props}
  />
)

export default ActivityStatusTypeProvider
