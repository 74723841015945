export class ArrayUtils {
  static groupBy (array, key) {
    return array.reduce((accumulator, item) => {
      let itemKey = item[key]
      if (typeof itemKey === 'object') {
        itemKey = itemKey['id']
      }
      accumulator[itemKey] = (accumulator[itemKey] || []).concat(item);
      return accumulator
    }, {})
  }
}
