import React, {Component} from 'react'
import moment from 'moment'

type Props = {
  startDate: any,
  stopDate?: any,
  totalPausesDuration?: number
}
type State = {
  currentValue: string
}

export default class Timer extends Component<Props, State> {
  static defaultProps = {
    totalPausesDuration: 0
  }

  tickInterval

  constructor (props) {
    super(props)

    this.state = {
      currentValue: this.getStringValueFromDate(props.startDate, props.stopDate || null)
    }
  }

  componentWillMount () {
    this.startCounter()
  }

  componentWillUnmount () {
    this.stopCounter()
  }

  componentDidUpdate(prevProps) {
    const { stopDate } = this.props
    if (prevProps.stopDate && !stopDate) {
      this.startCounter()
    }
  }

  startCounter = () => {
    if (!this.checkStopDate()) {
      this.tickInterval = setInterval(this.tick, 1000)
    }
  }

  stopCounter = () => {
    clearInterval(this.tickInterval)
  }

  getStringValueFromDate = (startDate, endDate) => {
    const { totalPausesDuration } = this.props
    const start = startDate ? moment(startDate) : moment()
    const end = endDate ? moment(endDate) : moment()
    const ms = (end.diff(start)) - totalPausesDuration
    const duration = moment.duration(ms)
    let returnString = '' + Math.floor(duration.asHours())
    if (returnString.length === 1) {
      returnString = '0' + returnString
    }

    return returnString + 'h' + moment.utc(ms).format(":mm[m]:ss[s]")
  }

  checkStopDate = () => {
    const { stopDate: stopDateProp } = this.props
    if (!stopDateProp) {
      return false
    }

    const now = moment()
    const stopDate = moment(stopDateProp)
    return now.isSameOrAfter(stopDate)
  }


  tick = () => {
    const { startDate, stopDate } = this.props

    if (this.checkStopDate()) {
      this.stopCounter()
    }

    this.setState({
      currentValue: this.getStringValueFromDate(startDate)
    })
  }

  render () {
    const { currentValue } = this.state
    return (
      <React.Fragment>
        {currentValue}
      </React.Fragment>
    )
  }

}
