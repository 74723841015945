import React, { Component } from 'react'
import { Field, Form, Formik } from 'formik'

type Props = {
  onSubmit: Function,
  initialValues: Object,
  submitLabel: string
}

export default class UserForm extends Component {
  render () {
    const { onSubmit, initialValues, submitLabel } = this.props
    return (
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        <Form>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="firstName">First Name</label>
              <Field
                type="text"
                name="firstName"
                id="firstName"
                className="form-control"
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="lastName">Last Name</label>
              <Field
                type="text"
                name="lastName"
                id="lastName"
                className="form-control"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="email">E-mail</label>
              <Field
                type="text"
                name="email"
                id="email"
                autoComplete="new-email"
                className="form-control"
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="phone">Phone</label>
              <Field
                type="text"
                name="phone"
                id="phone"
                className="form-control"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="password">Password</label>
              <Field
                type="password"
                name="password"
                id="password"
                autoComplete="new-password"
                className="form-control"
              />
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-lg btn-primary"
          >
            {submitLabel || 'Save'}
          </button>
        </Form>
      </Formik>
    )
  }
}
