import React, { Component } from 'react'
import { Switch } from 'react-router-dom'
import { Route } from 'react-router-dom'
import List from './scenes/List'

type Props = {
  match: any
}

export default class UserActivities extends Component<Props> {
  render () {
    const {match} = this.props
    return (
      <Switch>
        <Route path={`${match.url}/`} component={List} />
      </Switch>
    )
  }
}
