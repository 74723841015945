import gql from 'graphql-tag'

const LOGIN_MUTATION = gql`
    mutation ($credentials: LoginInput!) {
        login(credentials: $credentials) {
            user {
                id
                firstName
                lastName
                email
            }
            token
        }
    }
`

export const LocalStorageKeys = {
  authToken: 'authToken',
  authUser: 'authUser'
}

const singleton = Symbol('singleton')
const singletonEnforcer = Symbol('singleton enforcer')

class AuthService {
  client
  history
  token
  authUser

  constructor (enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error('Cannot construct singleton')
    }
  }

  static get instance () {
    if (!this[singleton]) {
      this[singleton] = new AuthService(singletonEnforcer)
    }

    return this[singleton]
  }

  init (client, history) {
    this.client = client
    this.history = history
    this.token = localStorage.getItem(LocalStorageKeys.authToken)
    this.authUser = JSON.parse(localStorage.getItem(LocalStorageKeys.authUser))
  }

  isUserAuthenticated () {
    return !!this.authUser && !!this.token
  }

  async login (email, password) {
    try {
      const response = await this.client.mutate({
        mutation: LOGIN_MUTATION,
        variables: {
          credentials: {
            email,
            password
          }
        }
      })
      if (response.data.login) {
        const {token, user} = response.data.login
        localStorage.setItem(LocalStorageKeys.authToken, token)
        localStorage.setItem(LocalStorageKeys.authUser, JSON.stringify(user))
        this.token = token
        this.authUser = user
        return response.data.login
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  logout () {
    localStorage.removeItem(LocalStorageKeys.authToken)
    localStorage.removeItem(LocalStorageKeys.authUser)
    this.client.resetStore()
    this.token = null
    this.authUser = null
    this.history.push('/login')
  }
}

export default AuthService
