import React, { Component } from 'react'
import { Redirect, Route } from 'react-router-dom'
import AuthService from '../../services/AuthService'

type Props = {
  component: Function
}

export default class PrivateRoute extends Component<Props> {
  render () {
    const {component: Component, ...rest} = this.props
    return (
      <Route
        {...rest}
        render={props =>
          AuthService.instance.isUserAuthenticated() ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location }
              }}
            />
          )
        }
      />
    )
  }
}
