import React, { Component } from 'react'
import { Field, Form, Formik } from 'formik'
import UserAutocomplete from '../../../../../../core/FormFields/UserAutocomplete'
import ProjectAutocomplete from '../../../../../../core/FormFields/ProjectAutocomplete'
import DateTimePicker from '../../../../../../core/FormFields/DateTimePicker'

type Props = {
  onSubmit: Function,
  initialValues: Object,
  submitLabel: string
}

export default class ActivityForm extends Component {
  render () {
    const { onSubmit, initialValues, submitLabel } = this.props
    return (
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {(form) => (
          <Form>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="user">Utente</label>
                <Field
                  name="user"
                  id="user"
                  component={UserAutocomplete}
                  inputClassName="form-control"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="project">Progetto</label>
                <Field
                  name="project"
                  id="project"
                  component={ProjectAutocomplete}
                  inputClassName="form-control"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="start">Inizio Attività</label>
                <Field
                  name="start"
                  id="start"
                  component={DateTimePicker}
                  inputClassName="form-control"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="end">Fine Attività</label>
                <Field
                  name="end"
                  id="end"
                  component={DateTimePicker}
                  inputClassName="form-control"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label htmlFor="end">Descrizione Attività</label>
                <Field
                  name="description"
                  id="description"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-lg btn-primary"
            >
              {submitLabel || 'Save'}
            </button>
          </Form>
        )}
      </Formik>
    )
  }
}
