export default class GridUtils {
  static getFilterObject = (columnName, value) => {
    return {
      columnName,
      value
    }
  }

  static getSortObject = (columnName, direction) => {
    return {
      columnName,
      direction
    }
  }
}
