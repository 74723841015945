import React, { Component } from 'react'
import { Field } from 'formik'
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import AutoSuggest from 'react-autosuggest'

const GET_PROJECTS_QUERY = gql`query GetProjects($filters: ProjectFilterInput) {
    projects(filters: $filters) {
        data {
            id
            name
            color
        }
    }
}`

type Props = {
  field: {
    onChange: Function,
    onBlur: Function,
    name: string,
    value: string
  },
  form?: Object,
  id: string,
  inputClassName?: string,
  client: Object,
  onChange?: Function
}

type State = {
  suggestions: Array,
  value: ''
}

class ProjectAutocomplete extends Component<Props, State> {

  constructor (props) {
    super(props)
    this.state = {
      value: props.field.value ? this.getSuggestionValue(props.field.value) : '',
      suggestions: []
    }
  }

  onSuggestionsFetchRequested = async ({ value }) => {
    const { client } = this.props
    const result = await client.query({
      query: GET_PROJECTS_QUERY,
      variables: {
        filters: {
          name: value
        }
      },
      fetchPolicy: 'no-cache'
    })
    this.setState({
      suggestions: result.data.projects.data
    })
  }

  onSuggestionsClearRequested = () => {
    this.setState({suggestions: []})
  }

  getSuggestionValue = (suggestion) => {
    return suggestion.name
  }

  onChange = (event, { newValue, method }) => {
    const { field, onChange } = this.props
    this.setState({
      value: newValue
    })

    if ((newValue === '' || !newValue) && onChange) {
      onChange(null)
    }
  }

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    const { field, form, onChange } = this.props
    const { name } = field
    form.setFieldValue(name, suggestion)
    if (onChange) {
      onChange(suggestion)
    }
  }

  renderSuggestion = (suggestion) => {
    return (
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', margin: 10}}>
        <div style={{
          width: '15px',
          height: '15px',
          marginRight: '8px',
          // marginLeft: '8px',
          backgroundColor: suggestion.color
        }} />

        {`${suggestion.name}`}
      </div>
    )
  }

  render () {
    const { suggestions, value } = this.state
    const { field, form, inputClassName, ...props } = this.props
    const {onChange, onBlur, name} = field

    /* if (error) {
      return (
        <div className="text-danger form-group">
          <div className="form-control">
            Errore durante il caricamento degli utenti
          </div>
        </div>
      )
    } */

    const inputProps = {
      value: value,
      onChange: this.onChange,
      className: inputClassName
    }
    return (
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        onSuggestionSelected={this.onSuggestionSelected}
        inputProps={inputProps}
        theme={{
          container: 'autosuggest',
          input: 'form-control',
          suggestionsContainer: 'dropdown',
          suggestionsList: `dropdown-menu ${suggestions.length ? 'show' : ''}`,
          suggestionHighlighted: 'text-dark'
        }}
        {...props}
      />
    )
  }
}

export default withApollo(ProjectAutocomplete)
