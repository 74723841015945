export const downloadCSV = (url, token, filters) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      body: JSON.stringify(filters),
      headers: {
        'Content-Type': "application/json",
        'Authorization': token,
      }
    }).then((response) => {
      return response.blob();
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject({message: "Error on downloading from: " + url, ...error});
    });
  });
};
