import React, { Component } from 'react'
import { Switch } from 'react-router-dom'
import { Route } from 'react-router-dom'
import Create from './scenes/Create'
import Edit from './scenes/Edit'
import Main from './scenes/Main'

type Props = {
  match: any
}

export default class AdminActivities extends Component<Props> {
  render () {
    const {match} = this.props
    return (
      <Switch>
        <Route path={`${match.url}/edit/:id`} component={Edit} />
        <Route path={`${match.url}/create`} component={Create} />
        <Route path={`${match.url}/`} component={Main} />
      </Switch>
    )
  }
}
