import React, { Component } from 'react'
import gql from 'graphql-tag'
import { compose, graphql } from 'react-apollo'
import UserForm from '../components/UserForm'
import history from '../../../../../../history'

const CREATE_USER_MUTATION = gql`mutation CreateUser($data: UserInput) {
    createUser(data: $data) {
        id
        firstName
        lastName
        email
        phone
    }
}`

type Props = {
  mutate: Function
}

class Create extends Component<Props> {
  constructor (props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
  }

  async onSubmit (values, { setSubmitting }) {
    try {
      const result = await this.props.mutate({
        variables: {
          data: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
            password: values.password
          }
        }
      })
      history.goBack()
    } catch (e) {
      console.error('errore', e)
    }
  }

  render () {
    return (
      <div className="col-12">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Crea Utente</h1>
        </div>
        <div className="col-8 offset-2">
          <UserForm
            onSubmit={this.onSubmit}
          />
        </div>
      </div>
    )
  }
}

export default compose(
  graphql(CREATE_USER_MUTATION)
)(Create)
