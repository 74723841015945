import React, { Component } from 'react'
import gql from 'graphql-tag'
import { compose, graphql, withApollo } from 'react-apollo'
import ReactTable from 'react-table'
import { NavLink } from 'react-router-dom'
import history from '../../../../../../history'
import { Field, Form, Formik } from 'formik'
import { DateUtils } from '../../../../../../utils/date'
import moment from 'moment'
import { Button, Popover, PopoverBody, PopoverHeader } from 'reactstrap'
import ProjectAutocomplete from '../../../../../../core/FormFields/ProjectAutocomplete'
import IosPlay from 'react-ionicons/lib/IosPlay'
import Timer from '../../../../../../core/Timer'
import { ActivityUtils } from '../../../../../../utils/activity'

const GET_MY_ACTIVITIES_QUERY = gql`query GetMyActivities(
$pagination: PaginationInput,
$filters: ActivityFilterInput,
$sort: ActivitySortInput
) {
    myActivities (pagination: $pagination, filters: $filters, sort: $sort) {
        data {
            id
            description
            start
            end
            durationMilliseconds
            pauses {
                id
                description
                start
                end
                durationMilliseconds
            }
            project {
                id
                name
                color
            }
        }
        limit
        offset
        total
    }
}`

const START_ACTIVITY_MUTATION = gql`mutation StartActivity($project: ID!) {
    startActivity(project: $project) {
        id
    }
}`
const PAUSE_ACTIVITY_MUTATION = gql`mutation PauseActivity($activity: ID!) {
    pauseActivity(activity: $activity) {
        id
    }
}`
const TERMINATE_PAUSE_ACTIVITY_MUTATION = gql`mutation TerminatePauseActivity($description: String, $activityPause: ID!) {
    terminatePauseActivity(activityPause: $activityPause, description: $description) {
        id
    }
}`
const TERMINATE_ACTIVITY_MUTATION = gql`mutation TerminateActivity($description: String!, $activity: ID!) {
    terminateActivity(activity: $activity, description: $description) {
        id
    }
}`

type Props = {
  data: any,
  match: any
}
type State = {
  columns: Array,
  isActivityManagerOpen: boolean
}

class List extends Component<Props, State> {

  constructor (props) {
    super(props)
    this.state = {
      isActivityManagerOpen: false,
      columns: [
        /* {
          Header: 'ID',
          accessor: 'id',
          resizable: true,
          sortable: true,
          filterable: true,
          width: 100
        }, */
        {
          Header: 'Project',
          accessor: 'project',
          sortable: false,
          filterable: false,
          width: 200,
          Cell: ({ value }) => {
            if (!value) {
              return null
            }

            const { name, color } = value
            return(
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                <div style={{
                  width: '20px',
                  height: '20px',
                  minWidth: '20px',
                  marginRight: '12px',
                  marginLeft: '8px',
                  backgroundColor: color
                }} />
                {`${name}`}
              </div>
            )
          }
        },
        {
          Header: 'Start time',
          accessor: 'start',
          resizable: true,
          sortable: true,
          filterable: true,
          width: 180,
          Cell: ({ value }) => {
            if (!value) {
              return null
            }

            return <div>{moment(value).format('L LT')}</div>
          }
        },
        {
          Header: 'Description',
          accessor: 'description',
          resizable: true,
          sortable: true,
          filterable: true
        },
        {
          Header: 'Duration',
          accessor: '',
          sortable: false,
          filterable: false,
          resizable: true,
          width: 150,
          Cell: ({ value }) => {
            if (!value || !value.durationMilliseconds) {
              return null
            }

            if (value.end) {
              return <div><b>{DateUtils.getHumanFormatDuration(value.durationMilliseconds - ActivityUtils.calculateTotalPauses(value.pauses))}</b></div>
            } else {
              return (
                <div>
                  <b>
                    <Timer
                      totalPausesDuration={
                        value.pauses.length > 0
                          ? ActivityUtils.calculateTotalPauses(value.pauses)
                          : 0
                      }
                      stopDate={(
                        value.pauses.length > 0 &&
                        !value.pauses[0].end
                      ) ? value.pauses[0].start : null}
                      startDate={value.start}
                    />
                  </b>
                </div>
              )
            }
          }
        },
        {
          Header: 'State',
          accessor: '',
          sortable: false,
          filterable: false,
          resizable: true,
          width: 140,
          Cell: ({ value }) => {
            if (!value) {
              return null
            }

            return <div style={{paddingLeft: '5px', paddingRight: '5px'}}>
              <span style={{
                color: value.end ? '#57d500' : '#ffbf00',
                marginRight: '8px',
                fontSize: '11px'
              }}>●</span>
              {value.end ? 'Completed' : 'Running'}
            </div>
          }
        }
      ]
    }

    this.onFetchData = this.onFetchData.bind(this)
    this.onRowClick = this.onRowClick.bind(this)
  }

  onFetchData (state) {
    const { data } = this.props
    const filterObj = {}

    state.filtered.forEach((f) => {
      let key = f.id

      if (key.split('.').length > 1) {
        key = key.split('.')[1]
      }

      filterObj[key] = f.value
    })

    let sortObj = {}
    state.sorted.forEach(s => {
      sortObj[s.id] = s.desc ? 'DESC' : 'ASC'
    })
    sortObj['start'] = 'DESC'

    data.refetch({
      pagination: {
        limit: state.pageSize,
        offset: state.page
      },
      filters: filterObj,
      sort: sortObj
    })
  }

  getTotalPages () {
    const { data } = this.props
    const { myActivities } = data

    if (myActivities.total !== 0) {
      return Math.ceil(myActivities.total / myActivities.limit)
    }

    return 0
  }

  onRowClick (row) {
    // const {match} = this.props
    // history.push(`${match.url}/edit/${row.id}`)
  }

  toggleActivityManager = () => {
    const { isActivityManagerOpen } = this.state
    this.setState({
      isActivityManagerOpen: !isActivityManagerOpen
    })
  }

  onStartActivity = async (values) => {
    try {
      const { client, data } = this.props
      const result = await client.mutate({
        mutation: START_ACTIVITY_MUTATION,
        variables: {
          project: values.project.id
        }
      })
      await data.refetch()
      this.setState({
        isActivityManagerOpen: false
      })
    } catch (e) {
      console.log('errore', e)
    }
  }
  onPauseActivity = async () => {
    try {
      const { client, data } = this.props
      const result = await client.mutate({
        mutation: PAUSE_ACTIVITY_MUTATION,
        variables: {
          activity: data.myActivities.data[0].id
        }
      })
      await data.refetch()
      this.setState({
        isActivityManagerOpen: false
      })
    } catch (e) {
      console.log('errore', e)
    }
  }

  onResumeActivity = async () => {
    try {
      const { client, data } = this.props
      const result = await client.mutate({
        mutation: TERMINATE_PAUSE_ACTIVITY_MUTATION,
        variables: {
          activityPause: data.myActivities.data[0].pauses[0].id// ,
          // description: values.description
        }
      })
      await data.refetch()
      /* this.setState({
        isActivityManagerOpen: false
      }) */
    } catch (e) {
      console.log('errore', e)
    }
  }
  onTerminateActivity = async (values) => {
    try {
      const { client, data } = this.props
      const result = await client.mutate({
        mutation: TERMINATE_ACTIVITY_MUTATION,
        variables: {
          activity: data.myActivities.data[0].id,
          description: values.description
        }
      })
      await data.refetch()
      this.setState({
        isActivityManagerOpen: false
      })
    } catch (e) {
      console.log('errore', e)
    }
  }

  render () {
    const { data, match } = this.props
    const {columns} = this.state

    if (!data || !data.myActivities) {
      return (
        <div>Caricamento in corso...</div>
      )
    }

    return (
      <div className="col-12">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">My Activities</h1>
          <div className="btn-toolbar mb-2 mb-md-0">

            {/* No activity running - START NEW ACTIVITY */}
            {(data.myActivities.data.length === 0 || (data.myActivities.data.length > 0 && data.myActivities.data[0].end)) && (
              <React.Fragment>
                <Button id="activityPopover" color="primary">
                  <IosPlay style={{marginBottom: '3px', marginRight: '4px'}} color="#ffffff" fontSize="1rem" />
                  Start a new activity
                </Button>
                <Popover placement="bottom" isOpen={this.state.isActivityManagerOpen} target="activityPopover" toggle={this.toggleActivityManager}>
                  <PopoverBody>
                    <div style={{marginTop: '15px'}}>
                      <Formik
                        onSubmit={this.onStartActivity}
                        initialValues={data.myActivities.data.length > 0 ? {project: data.myActivities.data[0].project} : null}
                      >
                        {({ values }) => (
                          <Form>
                            <div className="form-row">
                              <div className="form-group col-12">
                                <p className="mb-3 text-muted">
                                  Choose a project to start an activity
                                </p>
                                <label htmlFor="project">Progetto</label>
                                <Field
                                  name="project"
                                  id="project"
                                  component={ProjectAutocomplete}
                                  inputClassName="form-control"
                                />
                              </div>
                            </div>
                            <button
                              style={{width: '100%', marginBottom: '20px'}}
                              type="submit"
                              className="btn btn-success"
                            >
                              Start Activity
                            </button>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </PopoverBody>
                </Popover>
              </React.Fragment>
            )}
            {/* Activity Running - PAUSE ACTIVITY, STOP ACTIVITY */}
            {(data.myActivities.data.length > 0 && !data.myActivities.data[0].end) && (
              <React.Fragment>
                <a href="javascript:void(0)" className="btn btn-light" style={{marginRight: '10px'}}>
                  Running activity: <strong>{data.myActivities.data[0].project.name}</strong>
                </a>
                <a href="javascript:void(0)" className="btn btn-light" style={{marginRight: '10px'}}>
                  <strong>
                    <Timer
                      totalPausesDuration={
                        data.myActivities.data[0].pauses.length > 0
                          ? ActivityUtils.calculateTotalPauses(data.myActivities.data[0].pauses)
                          : 0
                      }
                      stopDate={(
                        data.myActivities.data[0].pauses.length > 0 &&
                        !data.myActivities.data[0].pauses[0].end
                      ) ? data.myActivities.data[0].pauses[0].start : null}
                      startDate={data.myActivities.data[0].start}
                  />
                  </strong>
                </a>
                {data.myActivities.data[0].pauses.length === 0 || (data.myActivities.data[0].pauses.length > 0 && data.myActivities.data[0].pauses[0].end) ? (
                  <React.Fragment>
                    <Button
                      onClick={this.onPauseActivity}
                      color="warning"
                      style={{marginRight: '10px'}}>
                      Pause
                    </Button>
                    <Button id="activityPopover" color="danger">Ends the activity</Button>
                    <Popover placement="bottom" isOpen={this.state.isActivityManagerOpen} target="activityPopover" toggle={this.toggleActivityManager}>
                      <PopoverBody>
                        <div style={{marginTop: '15px'}}>
                          <Formik
                            onSubmit={this.onTerminateActivity}
                            initialValues={data.myActivities.data.length > 0 ? {project: data.myActivities.data[0].project} : null}
                          >
                            {({ values }) => (
                              <Form>
                                <div className="form-row">
                                  <div className="form-group col-12">
                                    <p className="mb-3 text-muted">
                                      To complete the activity insert a short description of what you've done.
                                    </p>
                                    <label htmlFor="project">Activity Description</label>
                                    <Field
                                      name="description"
                                      id="description"
                                      type="text"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <button
                                  style={{width: '100%', marginBottom: '20px'}}
                                  type="submit"
                                  className="btn btn-danger"
                                >
                                  Complete Activity
                                </button>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </PopoverBody>
                    </Popover>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Button onClick={this.onResumeActivity} id="activityPopover" color="primary">
                      <IosPlay style={{marginBottom: '3px', marginRight: '4px'}} color="#ffffff" fontSize="1rem" />
                      Continue Activity
                    </Button>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}

          </div>
        </div>
        <ReactTable
          style={{maxHeight: '580px'}}
          manual
          filterable
          loading={data.loading}
          columns={columns}
          data={data.myActivities.data}
          getTrProps={(state, rowInfo, column, instance) => ({
            onClick: () => this.onRowClick(rowInfo.original)
          })}
          pages={this.getTotalPages()}
          className="-highlight"
          onFetchData={this.onFetchData}
          noDataText="No Activities"
          defaultPageSize={50}
          nextText="Next"
          previousText="Previous"
          loadingText="Loading..."
          rowsText="activities"
          ofText="of"
          pageText="Page"
        />
      </div>
    )
  }
}

export default compose(
  graphql(GET_MY_ACTIVITIES_QUERY, {
    variables: {
      sort: {
        start: 'DESC'
      }
    },
    fetchPolicy: 'network-only'
  })
)(withApollo(List))
