import React, { Component } from 'react'
import gql from 'graphql-tag'
import { compose, graphql } from 'react-apollo'
import UserForm from '../components/UserForm'
import history from '../../../../../../history'

const GET_USER_QUERY = gql`query GetUsers($id: ID!) {
    user(id: $id) {
        id
        firstName
        lastName
        email
        phone
    }
}`

const EDIT_USER_MUTATION = gql`mutation EditUser($id: ID!, $data: UserInput) {
    updateUser(id: $id, data: $data) {
        id
        firstName
        lastName
        email
        phone
    }
}`

const DELETE_USER_MUTATION = gql`mutation DeleteUser($id: ID!) {
    deleteUser(id: $id)
}`

type Props = {
  editMutate: Function,
  deleteMutate: Function,
  data: Object
}

class Edit extends Component<Props> {
  constructor (props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this)
  }

  async onDeleteConfirm () {
    try {
      const {deleteMutate, data} = this.props
      const {user} = data
      const result = await deleteMutate({variables: {id: user.id}})
      history.goBack()
    } catch (e) {
      console.log(e)
    }
  }

  async onSubmit (values, { setSubmitting }) {
    try {
      const { data } = this.props
      const { user } = data

      const result = await this.props.editMutate({
        variables: {
          id: user.id,
          data: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
            password: values.password
          }
        }
      })
      history.goBack()
    } catch (e) {
      console.error('errore', e)
    }
  }

  render () {
    const { data } = this.props
    const { loading } = data
    return (
      <div className="col-12">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Modifica Utente</h1>
        </div>
        <div className="col-8 offset-2">
          {loading ? (
            <span>Caricamento in corso...</span>
          ) : (
            <UserForm
              initialValues={data.user}
              onSubmit={this.onSubmit}
            />
          )}
          <button
            style={{marginTop: '20px'}}
            className="btn btn-danger"
            onClick={this.onDeleteConfirm}
          >
            Delete
          </button>
        </div>
      </div>
    )
  }
}

export default compose(
  graphql(GET_USER_QUERY, {
    options: ({match}) => ({ variables: { id: match.params.id } })
  }),
  graphql(EDIT_USER_MUTATION, { name: 'editMutate' }),
  graphql(DELETE_USER_MUTATION, { name: 'deleteMutate' })
)(Edit)
