import React, { Component } from 'react'
import gql from 'graphql-tag'
import { withApollo } from 'react-apollo'
import history from '../../../../../../../../history'
import queryString from 'query-string'
import {
  Grid, PagingPanel,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
  VirtualTable
} from '@devexpress/dx-react-grid-bootstrap4'
import ProjectTypeProvider from '../../../../../../../../core/Table/TypeProviders/ProjectTypeProvider'
import moment from 'moment'
import DurationTypeProvider from '../../../../../../../../core/Table/TypeProviders/DurationTypeProvider'
import ActivityStatusTypeProvider from '../../../../../../../../core/Table/TypeProviders/ActivityStatusTypeProvider'
import { CustomPaging, FilteringState, PagingState, SortingState } from '@devexpress/dx-react-grid'
import UserAutocompleteFilter from '../../../../../../../../core/Table/Filters/UserAutocompleteFilter'
import GridUtils from '../../../../../../../../utils/grid'
import ProjectAutocompleteFilter from '../../../../../../../../core/Table/Filters/ProjectAutocompleteFilter'
import DateRangeFilter from '../../../../../../../../core/Table/Filters/DateRangeFilter'
import { isInclusivelyBeforeDay } from 'react-dates'
import ActivityActionTypeProvider from '../../../../../../../../core/Table/TypeProviders/ActivityActionTypeProvider'

const DEFAULT_PAGE_SIZE = 20
const DEFAULT_COLUMNS_WIDTH = [
  { columnName: 'user', width: 180 },
  { columnName: 'project', width: 180 },
  { columnName: 'start', width: 180 },
  { columnName: 'description', width: 240 },
  { columnName: 'duration', width: 240 },
  { columnName: 'end', width: 100 },
  { columnName: 'actions', width: 100 },
]
const LOCAL_STORAGE_KEY_COLUMNS_WIDTH = 'adminActivitiesTableColumnsWidth'
const GET_ACTIVITIES_QUERY = gql`query GetActivities(
$pagination: PaginationInput,
$filters: ActivityFilterInput,
$sort: ActivitySortInput
) {
    activities (pagination: $pagination, filters: $filters, sort: $sort) {
        data {
            id
            description
            start
            end
            durationMilliseconds
            project {
                id
                name
                color
            }
            pauses {
                id
                description
                start
                end
                durationMilliseconds
            }
            user {
                id
                firstName
                lastName
                email
                phone
            }
        }
        limit
        offset
        total
    }
}`

const GET_USER_QUERY = gql`query GetUser($id: ID!) {
    user(id: $id) {
        id
        firstName
        lastName
        email
        phone
    }
}`

const GET_PROJECT_QUERY = gql`query GetProject($id: ID!) {
    project(id: $id) {
        id
        name
        color
    }
}`

const Root = props => <Grid.Root {...props} style={{height: 'calc(100%-4rem)'}} />;

type Props = {
  match: any,
  client: any
}
type State = {
  data: [],
  loading: boolean,
  page: number,
  pageSize: number,
  columns: Array,
  filters: Array,
  sorting: Array,
  userFilterInputValue: string,
  projectFilterInputValue: string,
  columnWidths: Array,
  windowHeight: number,
  totalData: number
}

class List extends Component<Props, State> {
  tableContainerRef

  constructor (props) {
    super(props)
    this.tableContainerRef = React.createRef()

    const columnsWidth = this.getColumnsWidthFromLocalStorage()
    this.state = {
      data: [],
      loading: true,
      page: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      columns: [
        {
          name: "user",
          title: "User",
          getCellValue: row => (row.user ? `${row.user.firstName} ${row.user.lastName}` : null)
        },
        {
          name: "project",
          title: "Project"
        },
        {
          name: "start",
          title: "Date",
          getCellValue: row => (row.start ? `${moment(row.start).format('L')}` : null)
        },
        {
          name: "description",
          title: "Description"
        },
        {
          name: "duration",
          title: "Duration",
          getCellValue: row => (row)
        },
        {
          name: "end",
          title: "Status"
        },
        {
          name: "actions",
          title: "",
          getCellValue: row => (row)
        }
      ],
      columnWidths: columnsWidth,
      filters: [],
      userFilterInputValue: '',
      projectFilterInputValue: '',
      sorting: [{columnName: 'start', direction: 'desc'}]
    }
  }

  getColumnsWidthFromLocalStorage = () => {
    let columnsWidth = DEFAULT_COLUMNS_WIDTH
    let localStorageData = localStorage.getItem(LOCAL_STORAGE_KEY_COLUMNS_WIDTH)
    if (localStorageData) {
      const columnsFromLocalStorage = JSON.parse(localStorageData)
      columnsWidth = columnsWidth.map(column => {
        const foundedItem = columnsFromLocalStorage.filter(storageColumn => storageColumn.columnName === column.columnName)
        if (foundedItem.length > 0) {
          return foundedItem[0]
        } else {
          return column
        }
      })
    }

    return columnsWidth
  }

  async componentDidMount () {
    this.setState({loading: true})
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
    await this.setStateFromQueryParams()
    await this.fetchActivities()
    this.setState({loading: false})
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const { location } = this.props
    if (prevProps.location.search !== location.search) {
      await this.setStateFromQueryParams()
      await this.fetchActivities()
    }
  }

  setStateFromQueryParams = () => {
    return new Promise(async (resolve, reject) => {
      const { location } = this.props
      const parsed = queryString.parse(location.search)
      const filters = []
      const sorting = []
      let page = this.state.page
      let pageSize = this.state.pageSize
      let newUserFilterValue = ''
      let newProjectFilterValue = ''

      const parsedKeys = Object.keys(parsed)
      for (let i = 0; i < parsedKeys.length; i++) {
        const key = parsedKeys[i]
        const item = parsed[key]
        if (key.endsWith("Filter")) {
          const columnName = key.replace('Filter', '')
          switch (columnName) {
            case 'user':
              const user = await this.fetchUser(item)
              if (user) {
                filters.push(GridUtils.getFilterObject(columnName, user))
                newUserFilterValue = user.firstName + ' ' + user.lastName
              }
              break
            case 'project':
              const project = await this.fetchProject(item)
              if (project) {
                filters.push(GridUtils.getFilterObject(columnName, project))
                newProjectFilterValue = project.name
              }
              break
            case 'startDate':
            case 'endDate':
              if (!item) {
                break
              }

              let foundedIndex = -1
              for (let i = 0; i < filters.length; i++) {
                const filter = filters[i]
                if (filter.columnName === 'start') {
                  foundedIndex = i
                  break
                }
              }

              if (foundedIndex > -1) {
                filters[foundedIndex] = GridUtils.getFilterObject(
                  'start',
                  {
                    ...filters[foundedIndex].value,
                    [columnName]: moment(item)
                  }
                )
              } else {
                filters.push(GridUtils.getFilterObject('start', { [columnName]: moment(item) }))
              }
              break
            case 'description':
              filters.push(GridUtils.getFilterObject(columnName, item))
              break
          }
        }

        if (key.endsWith('Sort')) {
          const columnName = key.replace('Sort', '')
          switch (columnName) {
            case 'start':
              sorting.push(GridUtils.getSortObject(columnName, item))
              break
          }
        }

        if (key === 'page') {
          page = parseInt(item)
        }
        if (key === 'pageSize') {
          pageSize = parseInt(item)
        }
      }

      this.setState({
        filters: filters,
        userFilterInputValue: newUserFilterValue,
        projectFilterInputValue: newProjectFilterValue,
        page: page,
        pageSize: pageSize,
        sorting: sorting
      }, () => {
        resolve()
      })
    })
  }

  addUrlParams = () => {
    const { filters, page, pageSize, sorting } = this.state
    const queryParams = {}
    filters.forEach(item => {
      switch (item.columnName) {
        case 'user':
          if (item.value) {
            queryParams['userFilter'] = item.value.id
          }
          break
        case 'project':
          if (item.value) {
            queryParams['projectFilter'] = item.value.id
          }
          break
        case 'start':
          if (item.value.startDate) {
            queryParams['startDateFilter'] = item.value.startDate
          }
          if (item.value.endDate) {
            queryParams['endDateFilter'] = item.value.endDate
          }
          break
        case 'description':
          queryParams['descriptionFilter'] = item.value
          break
      }
    })
    sorting.forEach(item => {
      switch (item.columnName) {
        case 'start':
          queryParams['startSort'] = item.direction
          break
      }
    })
    queryParams.page = page
    queryParams.pageSize = pageSize
    history.push({
      search: queryString.stringify(queryParams)
    })
  }

  fetchActivities = async () => {
    try {
      const { client } = this.props
      const { page, pageSize, filters, sorting } = this.state
      const queryFilters = filters.reduce((acc, item) => {
        switch (item.columnName) {
          case 'user':
            if (item.value) {
              acc[item.columnName] = item.value.id
            }
            break
          case 'project':
            if (item.value) {
              acc[item.columnName] = item.value.id
            }
            break
          case 'start':
            if (item.value.startDate) {
              acc['betweenDateStart'] = item.value.startDate
            }
            if (item.value.endDate) {
              acc['betweenDateEnd'] = item.value.endDate
            }
            break
          case 'description':
            acc[item.columnName] = item.value
            break
        }
        return acc
      }, {})
      const sort = sorting.reduce((acc, item) => {
        switch (item.columnName) {
          case 'start':
            acc[item.columnName] = item.direction.toUpperCase()
            break
        }
        return acc
      }, {})

      this.setState({loading: true})
      const result = await client.query({
        query: GET_ACTIVITIES_QUERY,
        variables: {
          pagination: {
            limit: pageSize,
            offset: page
          },
          filters: queryFilters,
          sort: sort
        }
      })

      this.setState({
        data: result.data.activities.data,
        totalData: result.data.activities.total,
        page: result.data.activities.offset,
        pageSize: result.data.activities.limit
      })
    } catch (e) {
      console.log(e)
    }
  }

  fetchUser = async (id) => {
    try {
      const { client } = this.props
      const result = await client.query({
        query: GET_USER_QUERY,
        variables: {
          id: id
        },
        fetchPolicy: 'no-cache'
      })
      return result.data.user
    } catch (e) {
      return null
    }
  }

  fetchProject = async (id) => {
    try {
      const { client } = this.props
      const result = await client.query({
        query: GET_PROJECT_QUERY,
        variables: {
          id: id
        },
        fetchPolicy: 'no-cache'
      })
      return result.data.project
    } catch (e) {
      return null
    }
  }

  onFiltersChange = filters => {
    this.setState({filters: filters}, () => {
      this.addUrlParams()
    })
  }

  onUserFilterInputChange = (e, {newValue, method}) => {
    this.setState({userFilterInputValue: newValue})
  }

  onProjectFilterInputChange = (e, {newValue, method}) => {
    this.setState({projectFilterInputValue: newValue})
  }

  onColumnWidthsChange = (columnWidths) => {
    this.setState({ columnWidths }, () => {
      localStorage.setItem(LOCAL_STORAGE_KEY_COLUMNS_WIDTH, JSON.stringify(columnWidths))
    })
  };

  renderFilter = (props) => {
    const { column } = props
    const { userFilterInputValue, projectFilterInputValue } = this.state
    switch (column.name) {
      case 'user':
        return (
          <th>
            <UserAutocompleteFilter
              {...props}
              inputValue={userFilterInputValue}
              inputOnChange={this.onUserFilterInputChange}
              id="activities-table-user-filter"
              inputClassName="form-control"
            />
          </th>
        )
      case 'project':
        return (
          <th>
            <ProjectAutocompleteFilter
              {...props}
              inputValue={projectFilterInputValue}
              inputOnChange={this.onProjectFilterInputChange}
              id="activities-table-project-filter"
              inputClassName="form-control"
            />
          </th>
        )
      case 'start':
        return (
          <th>
            <DateRangeFilter
              {...props}
              startDateId="activities-table-start-date-filter"
              endDateId="activities-table-end-date-filter"
              isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
              small
              block
              withoutBorders
            />
          </th>
        )
      case 'description':
        return <TableFilterRow.Cell {...props} />
      default:
        return <th />
      // return
    }
  }

  updateWindowDimensions = () => {
    this.setState({windowHeight: window.innerHeight || 0})
  }

  onCurrentPageChange = (currentPage) => {
    const { pageSize, totalData } = this.state
    if (totalData > 0) {
      let newPage = currentPage
      const totalPages = Math.ceil(totalData / pageSize)
      if (newPage - 1 > totalPages) {
        newPage = totalPages - 1
      }
      if (newPage < 0) {
        newPage = 0
      }
      this.setState({
        page: newPage
      }, () => {
        this.addUrlParams()
      })
    }

  }

  onPageSizeChange = (pageSize) => {
    this.setState({
      pageSize: pageSize
    }, () => {
      this.addUrlParams()
    })
  }

  onSortingChange = (sorting) => {
    this.setState({
      sorting: sorting
    }, () => {
      this.addUrlParams()
    })
  }

  render () {
    const { data, loading, columns, filters, columnWidths, windowHeight, pageSize, page, totalData, sorting } = this.state

    if (!data) {
      return (
        <div>Caricamento in corso...</div>
      )
    }

    return (
      <Grid
        rows={data}
        columns={columns}
        rootComponent={Root}
      >
        <PagingState
          currentPage={page}
          onCurrentPageChange={this.onCurrentPageChange}
          pageSize={pageSize}
          onPageSizeChange={this.onPageSizeChange}
        />
        <CustomPaging
          totalCount={totalData}
        />
        <FilteringState
          filters={filters}
          onFiltersChange={this.onFiltersChange}
        />
        <SortingState
          sorting={sorting}
          onSortingChange={this.onSortingChange}
        />
        <ProjectTypeProvider for={['project']} />
        <DurationTypeProvider for={['duration']} />
        <ActivityStatusTypeProvider for={['end']} />
        <ActivityActionTypeProvider for={['actions']} />
        <VirtualTable height={windowHeight ? windowHeight - 200 : 0} />
        <TableColumnResizing
          columnWidths={columnWidths}
          onColumnWidthsChange={this.onColumnWidthsChange}
        />
        <TableHeaderRow showSortingControls />
        <TableFilterRow cellComponent={this.renderFilter} />
        <PagingPanel
          pageSizes={[5, 10, 20, 40, 50, 100]}
        />
      </Grid>
    )
  }
}

export default withApollo(List)
