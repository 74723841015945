import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
// import { createUploadLink } from 'apollo-upload-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import AuthService from './services/AuthService'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'

const httpLink = createHttpLink({
  uri: '/graphql'
})

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: AuthService.instance.token
    }
  }
})

const logoutLink = onError((data) => {
  if (data.graphQLErrors && data.graphQLErrors[0].message === 'Authorization Error') {
    AuthService.instance.logout()
  }
})


export const createClient = () => new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})
